import React, { useState } from "react";

const ContactThree = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch("/api/send_mail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatus("Message sent successfully!");
        setIsSent(true); // Update to indicate the message has been sent
      } else {
        setStatus("Failed to send message.");
      }
    } catch (error) {
      setStatus("Error: " + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="contact_form_area contact_us section_padding">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="contact-right contact_details">
              <div className="hero-title-with-shape">
                <h4 className="heading_with_border">Contact with us</h4>
                <h2 style={{ fontSize: "40px" }}>
                  Our Customer Representatives are Ready 24*7 to Assist You
                </h2>
              </div>
              <p>
                Whether it’s a pest problem or confusion regarding ongoing
                services, contact us anytime.
              </p>
              <div className="contact_location_box">
                <div className="contact_location_map">
                  <span className="icon-location"></span>
                  <p>
                    Max Trade Centre, 23 Luk Hop St, San Po Kong, <br />
                    Hong Kong
                  </p>
                </div>
                <div className="contact_location_map contact_location_call">
                  <span className="icon-contact_call"></span>
                  <p>
                    info@nifixeci.com <br />
                    +852 9888 2051
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="contact-right contact-right-style-2 responsive_mt">
              <form onSubmit={handleSubmit}>
                <input
                  className="half_width input_m_right"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Your name"
                  required
                  disabled={isSubmitting || isSent}
                />
                <input
                  className="half_width"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email address"
                  required
                  disabled={isSubmitting || isSent}
                />
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Phone number"
                  required
                  disabled={isSubmitting || isSent}
                />
                <textarea
                  name="message"
                  id="message"
                  cols="20"
                  rows="5"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Write message"
                  required
                  disabled={isSubmitting || isSent}
                ></textarea>
                <button
                  className="btn-yellow"
                  type="submit"
                  disabled={isSubmitting || isSent}
                >
                  {isSent
                    ? "MESSAGE SENT"
                    : isSubmitting
                    ? "SENDING..."
                    : "SUBMIT NOW"}
                </button>
                {status && <p>{status}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactThree;
